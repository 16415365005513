body {
	/* margin: 0px 150px 0px 150px; */
	margin: 0 5%;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* PhotoFeed.css */
.photo-img {
	max-width: 50px;
	max-height: 50px;
	object-fit: cover;
}
